.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: $white;
  border-bottom: 1px solid #e6e6e6;
  position: relative;

  .menu {
    margin-top: 0;
  }

  .mobile-trigger {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    cursor: pointer;
    z-index: 102;
    display: none;

    span {
      display: block;
      width: 100%;
      height: 3px;
      background: $brand-primary;
      margin-bottom: 4px;
      transform: translateX(0px);
      transition: all 0.15s ease;

      &.last {
        margin-bottom: 0;
      }
    }

    &.open {
      .first,
      .last {
        transform: translateX(-2px);
      }

      .middle {
        transform: translateX(2px);
      }
    }
  }

  nav {
    float: right;

    ul {
      margin: 0;

      li {
        display: inline-block;
        list-style: none;
        margin-right: 0;
        position: relative;
        padding: 15px 10px;

        i {
          display: none;
        }

        &.menu-item--30 a{
          color: $white;
          padding: 10px 15px;
          border-radius: 3px;
          background: $brand-primary;
          display: block;
          transition: all 0.2s ease;

          &:hover,
          &:focus {
            text-decoration: none;
            background: lighten($brand-primary, 4%);
          }
        }

        &.menu-item--21 a{
          color: $white;
          padding: 10px 15px;
          border-radius: 3px;
          background: $brand-success;
          display: block;
          transition: all 0.2s ease;

          &:hover,
          &:focus {
            text-decoration: none;
            background: lighten($brand-success, 4%);
          }
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          .sub-nav {
            z-index: 2;
            opacity: 1;
            transform: translateX(-50%) translateY(0px);
            transition: z-index 0s, opacity 0.2s ease 0.1s, transform 0.2s ease 0.1s;
          }
        }

        .sub-nav {
          display: block;
          position: absolute;
          width: 250px;
          text-align: left;
          left: 50%;
          transform: translateX(-50%) translateY(30px);
          padding: 2rem;
          background: $brand-primary;
          box-shadow: 4px 4px 9px rgba(0,0,0,0.1);
          margin-top: 15px;
          border-radius: 3px;
          z-index: -22;
          opacity: 0;
          transition: transform 0.2s ease, opacity 0.2s ease, z-index 0s ease 0.2s;

          &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: 50%;
            border-radius: -16px;
            width: 14px;
            height: 14px;
            background: $brand-primary;;
            border-radius: 2px;
            transform: rotateZ(45deg) translateX(-50%);
            z-index: -1;
          }

          li {
            line-height: 14px;
            padding: 8px 0;
            //border-bottom: 1px solid darken($brand-primary, 7%);
            display: block;

            &:first-of-type {
              padding-top: 0;
            }

            &:last-of-type {
              padding-bottom: 0;
              border-bottom: 0;
            }

            a {
              color: $white;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        a {
          font-weight: 500;
          color: $brand-primary;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
  }

  .home__link {
    font-size: 24px;
    padding: 1rem;
    color: $brand-primary;
    border-radius: 3px;
    font-weight: bold;
    float: left;
    margin-top: 4px;
    font-family: 'Roboto Slab';
    align-items: center;
    display: flex;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    img {
      width: 40px;
      margin-right: 15px;
    }
  }

  @media all and (max-width: 991px) {
    text-align: center;

    .mobile-trigger {
      display: block;
    }

    .home__link {
      margin: 0 auto;
      display: inline-block;
      margin-bottom: 1rem;
      float: none;
    }

    nav {
      float: none;
      text-align: center;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0px;
      padding: 5rem;
      top: 0;
      z-index: -1;
      opacity: 0;
      background: $white;
      transition: opacity 0.2s ease, z-index 0.2s ease 0.2s;

      ul {
        position: absolute;
        top: 50%;
        transform: translateY(-30%);
        left: 0;
        right: 0;
        padding: 5rem;
        transition: all 0.2s ease;

        li {
          display: block;
          margin: 0 0 20px 0;

          &:hover,
          &:focus {
            ul.sub-nav {
              transform: none;
              transition: none;
            }
          }

          i {
            display: inline-block;
            padding-left: 20px;
            color: $brand-primary;

            &::before {
              display: block;
              transform: rotateZ(0deg);
            }

            &.active::before {
              transform: rotateZ(180deg);
            }
          }

          a {
            display: inline-block !important;
          }

          ul.sub-nav {
            display: none;
            opacity: 1;
            z-index: 1;
            position: relative;
            background: transparent;
            padding: 20px 0 0 0;
            margin: 0;
            box-shadow: none;
            transform: none;
            left: 0;

            &::before {
              display: none;
            }

            &:hover,
            &:focus {
              transform: none;
              transition: none;
            }

            li {
              margin: 0;

              a {
                color: $brand-primary;
              }
            }
          }
        }
      }

      &.open {
        opacity: 1;
        z-index: 100;
        transition: all 0.2s ease;

        ul {
          transform: translateY(-50%);
          transition: all 0.2s ease;
        }
      }
    }
  }
}