.course-index-page {
  background: $white;

  .content-wrapper {
    display: flex;
    justify-content: center;
    z-index: 0;
  }
  .course-index {
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .course-container {
    background: $white;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    border: solid 1px $black;
    padding: 0;
    margin-bottom: 50px;
    margin-top: 50px;

    .course-image {
      background: $white;
      background-size: cover;
      height: 100%;
      width: 25%;
    }

    h1 {
      text-align: center;
      padding-bottom: 1rem;
      font-family: $font-family-serif;
    }

    img {
      margin: 0 auto 4rem auto;
      max-width: 70%;
      display: block;
    }
  }

  .ad-container {
    margin: 5rem 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .quiz-box {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    padding: 1rem;

    .quiz-box-inner {
      padding: 2rem;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      margin: 1rem;
      text-align: center;
      flex: 1 0 90%;

      h3 {
        margin-top: 0;
      }

      .button-wrapper {
        margin-bottom: 0;

        .quiz-button {
          display: inline-block;
          color: $white;
          background: $brand-success;
          padding: 1rem 3rem;
          border-radius: 3px;
          font-size: 24px;
          text-transform: uppercase;
          transition: all 0.2s ease;
          font-weight: 700;
          letter-spacing: 2px;

          &:hover,
          &:focus {
            background: darken($brand-success, 7%);
            text-decoration: none;
          }

          &.disabled {
            opacity: 0.4;
            cursor: not-allowed;

            &:hover,
            &:focus {
              background: $brand-success;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 991px) {
    .course-index {
      padding: 3rem;
      margin-left: 5px;
      z-index: 0
    }

    .ad-container {
      display: block;
    }

    .course-container {
      margin-top: 0;
      height: fit-content;

      .course-image {
        height: 100px;
        width: 100%;
        margin-left: 0;
        background-position: center;
        background-size: cover;
      }
    }
  }
}