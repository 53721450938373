.result {
  background: url('../images/playstation-pattern.png');

  .container-wrapper {
    position: relative;
    padding-top: 2rem;
  }

  .content-wrapper .container {
    margin-bottom: 40px;
  }

  .header .container {
    margin-bottom: 0;
  }

  #container {
    width: 70%;
    height: 300px;
    margin: 0 auto;
  }

  .title {
    font-size: 21px;
    font-style: italic;
    margin-bottom: 50px;
  }

  h2.score {
    text-align: center;
    position: absolute;
    font-size: 18px;
    padding: 28px 19px;
    background: $brand-success;
    border-radius: 50%;
    color: #fff;
    left: 20%;
    top: 53px;
    z-index: 2;
  }

  .price-blocks {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;

    .price-block {
      $radius: 12px;
      flex: 0 0 30%;
      position: relative;
      padding: 2rem 2rem 11.7rem;
      border-radius: 4px;
      background: $white;
      border: 1px solid #eee;
      overflow: hidden;
      box-shadow: 1px 1px 15px rgba(0,0,0,0.1);

      &::before {
        position: absolute;
        background: transparentize($gray, .9);
        top: -$radius;
        left: -$radius;
        width: $radius * 2;
        height: $radius * 2;
        display: block;
        border-radius: 50%;
        content: '';
        box-shadow:
                0 0 0 $radius * 2 transparentize($gray, .95);
      }

      &:last-of-type::before {
        background: transparentize($gray, .8);
        box-shadow:
                0 0 0 $radius * 2 transparentize($gray, .95),
                0 0 0 $radius * 4 transparentize($gray, .95),
                0 0 0 $radius * 6 transparentize($gray, .95);
      }

      &.most-popular {
        border: 2px solid $brand-primary;
        box-shadow: 4px 4px 25px rgba(0,0,0,0.4);
        position: relative;
        top: -20px;

        &::before {
          background: transparentize($brand-primary, .85);
          box-shadow:
                  0 0 0 $radius * 2 transparentize($brand-primary, .95),
                  0 0 0 $radius * 4 transparentize($brand-primary, .95);
        }

        .tagline {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0 auto;
          padding: .5rem 2rem;
          border-bottom-left-radius: 4px;
          background: $brand-primary;
          color: $white;
          font-size: 12px;
          font-weight: 500;
        }

        h3,
        .price {
          color: $brand-primary;
        }
      }

      .price {
        font-size: 24px;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 1rem .5rem;
          border-bottom: 1px solid #eee;
          font-size: 13px;
        }

        .text-bold {
          font-weight: 500;
        }
      }

      form {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;

        .stripe-button-el {
          background: transparent;
          border-radius: 4px;
          padding: 0;
          box-shadow: none;
          border: 0;

          span {
            background: $brand-primary;
            border-radius: 4px;
            border: 0;
            box-shadow: none;
            padding: 0.5rem 2rem;
            height: auto;
            transition: all 0.2s ease;

            &:hover,
            &:focus {
              background: darken($brand-primary, 7%);
            }

            &:active {
              background: darken($brand-primary, 10%);
            }
          }
        }
      }
    }
  }

  .encryption-notice {
    clear: both;
  }

  @media all and (max-width: 767px) {
    .price-blocks {
      flex-wrap: wrap;

      .price-block {
        flex: 0 0 80%;
        margin-bottom: 2rem;

        &.most-popular {
          top: 0;
          box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
        }
      }
    }
  }
}