
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "custom";
@import "header";
@import "front";
@import "quiz";
@import "result";
@import "landing-page";
@import "purchase-page";
@import "course";
