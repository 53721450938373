html, body {
  background-color: #fff;
  color: #636b6f;
  font-weight: 400;
  height: 100vh;
  margin: 0;

  &.nav-open {
    overflow: hidden;
  }
}

body {
  min-height: 100%;
  height: auto;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab';
  font-weight: bold;
}

.hero-section {
  padding: 80px 40px;
  background: url("/images/hero-bg-pattern-lg.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
  top: -3rem;

  h1 {
    text-align: center;
    color: $white;
  }
}

img {
  max-width: 100%;
}

.content-container {
  margin-bottom: 4rem;
}

.content-wrapper {
  padding-bottom: 128px;
  padding-top: 3rem;
  line-height: 24px;

  p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.58;
  }

  .above-content-ad-space,
  .below-content-ad-space {
    .mobile {
      display: none;
    }

    .full-width {
      display: block;
    }

    @media all and (max-width: 800px) {
      .mobile {
        display: block;
      }

      .full-width {
        display: none;
      }
    }
  }
}

.inner-container {
  max-width: 1170px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.subnav {
  margin-top: 22px;

  .menu {
    border-radius: 4px;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      border-top: 1px solid #ececec;
      line-height: 1.2;

      &:first-of-type {
        border-top: 0;
      }

      &.active > a {
        font-weight: 500;
      }

      &.has-children.active,
      &.child-is-active {
        border-top: 0;

        > a {
          background: transparentize($brand-primary, .8);
        }
      }

      a {
        color: $brand-primary;
        display: block;
        padding: 1rem;

        i {
          float: right;
        }
      }

      ul {
        padding-left: 15px;

        li:first-of-type {
          border-top: 0;
        }
      }
    }
  }
}

.error-page {
  text-align: center;

  .container {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
  }

  h1 {
    font-size: 156px;
    color: #e8e8e8;
    margin-top: 0;
  }

  h3 {
    margin-top: 0;
  }
}

.above-footer {
  //background: url('/images/hero-bg-pattern-lg.jpg');
  padding: 6rem 2rem;
  background-size: cover;

  .inner {
    padding: 6rem 30rem 6rem 6rem;
    background: #e8f2ff;
    box-shadow: 6px 12px 19px rgba(0, 0, 0, 0.1);
    position: relative;

    h2 {
      margin-top: 0;
      margin-bottom: 2rem;
      font-family: 'Roboto Slab';
      font-weight: bold;
      color: $brand-primary;
    }

    p {
      margin-bottom: 2rem;
      font-size: 18px;
      color: $brand-primary;
    }

    a {
      font-size: 18px;
      padding: 10px 30px;
      border-radius: 4px;
      color: $white;
      background: $brand-primary;
      transition: all 0.3s ease;
      display: inline-block;

      &.view-sample {
        color: $brand-primary;
        background: #e8f2fe;
        border: 1px solid $brand-primary;

        &:hover,
        &:focus {
          background: #e8f2fe;
          color: darken($brand-primary, 7%);
          border-color: darken($brand-primary, 7%);
          text-decoration: none;
        }
      }

      &:hover,
      &:focus {
        background: darken($brand-primary, 7%);
        text-decoration: none;
      }
    }

    img {
      width: 220px;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}

.quick-quiz-button,
.full-quiz-button {
  margin-top: 10px;
  margin-bottom: 10px;

  a {
    padding: 20px;
    display: block;
    border: 2px solid;
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 2px 2px 19px rgba(0,0,0,0.15);
    background: $white;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background: $brand-primary;
      color: $white;
      text-decoration: none;
      border-color: $brand-primary;
    }
  }
}

.full-quiz-button a {
  color: $brand-success;

  &:hover,
  &:focus {
    background: $brand-success;
    color: $white;
    border-color: $brand-success;
  }
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  padding-top: 45px;
  padding-bottom: 15px;
  //background: darken($brand-primary, 30%);
  background: #f9f9f9;

  a {
    color: $brand-primary;
  }

  h2 {
    font-size: 16px;
    font-weight: 900;
  }

  .copyright-info {
    padding-bottom: 20px;
  }

  .site-map {
    padding-top: 40px;
    border-top: 1px solid #eee;

    a {
      color: #a9a9a9;
    }

    ul {
      padding: 0;

      li {
        list-style: none;

        a.top-level {
          font-size: 18px;
          font-weight: bold;
        }

        ul {
          padding-left: 20px;
        }
      }
    }
  }
}


.checkout-page {
  background: url('/images/playstation-pattern.png');

  .alert {
    margin-top: 2rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .content {
    background: $white;
    padding: 4rem;
    border: 1px solid #eee;
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .product-info {
    background: $white;
    padding: 2rem 4rem;
    border: 1px solid #eee;
    margin-bottom: 4rem;

    p {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-weight: bold;

      .price {
        font-weight: normal;
      }
    }
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
    max-width: 500px;

    label {
      margin-bottom: -1px;
      display: flex;
      align-items: center;
      border: 1px solid #eeeeee;
      padding: 7px 0 7px 20px ;
      background: #fff;
      //border-radius: 3px;
      //box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.05);

      span {
        flex: 0 0 100px;
      }

      input {
        width: 100%;
        border: 0;
        padding-right: 20px;
        font-weight: normal;

        &::-webkit-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &::-moz-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &:-ms-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
      }

      select {
        width: 100%;
        flex: 1;
        border-style: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #313b3f;
        cursor: pointer;
        background: transparent;
      }
    }
  }

  .stripe-card,
  .country {
    width: 100%;
  }

  .StripeElement {
    flex: 1;
  }

  .payment-info {
    margin-bottom: 2rem;
  }

  .pay-with-stripe {
    display: block;
    margin-top: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $brand-primary;
    color: $white;
    padding: 12px 20px;
    border: 0;
    font-family: $headings-font-family;
    font-weight: normal;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      background: darken($brand-primary, 7%);
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover,
      &:focus {
        background: $brand-primary;
      }
    }
  }
}

.buy-wrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 70%;
  margin: 0 auto;

  p {
    margin-bottom: 0;
  }

  .buy-button {
    background: $brand-primary;
    padding: 1rem 3rem;
    display: inline-block;
    color: $white;
    border-radius: 2px;
    box-shadow: 2px 2px 9px transparentize($brand-primary, .7);
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      background: darken($brand-primary, 7%);
      box-shadow: 1px 1px 4px transparentize($brand-primary, .7);
    }
  }
}

@media all and (max-width: 991px) {
  .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .above-footer {
    .inner {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;

      p {
        margin-bottom: 2rem;
      }

      p, a {
        flex: 0 0 100%;
      }
    }
  }
}

@media all and (max-width: 730px) {
  .above-footer {
    .inner {
      padding: 14rem 6rem 6rem 6rem;

      img {
        width: 220px;
        right: 0;
        left: 0;
        top: -10rem;
        position: absolute;
        margin: 0 auto;
        transform: none;
      }
    }
  }
}