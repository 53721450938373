#countdown {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  border: 0;
  background: $brand-primary;
  color: $white;
  z-index: 10;

  .countdown-amount {
    font-size: 130%;
    display: block;
    padding: 11px 2px;
  }
}

body.quiz {
  padding-top: 44px;
  background: #f6f6f6;

  .quiz-form-row {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .quiz-ad-top,
  .quiz-ad-bottom {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .ad-small {
      display: none;
    }
  }

  .content {
    padding: 4rem;
    background: $white;
    border-radius: 4px;
    box-shadow: 1px 1px 13px rgba(0,0,0,0.05);

    form {
      @include clearfix;
    }

    .form-item {
      margin-bottom: 1rem;

      input[type="radio"] {
        display: none;

        &:checked + label {
          background-color: $text-color;
          color: $white;
        }
      }

      input[type="text"] {
        width: 100%;
        padding: 1rem;
        border: 1px solid $text-color;
      }

      label {
        width: 100%;
        padding: 1rem;
        border: 1px solid $text-color;
        color: $text-color;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.15s ease;
      }

      button {
        border: 0;
        padding: 1rem 3rem;
        background: $brand-success;
        color: $white;
        transition: all 0.2s ease;
        float: right;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover,
        &:focus {
          background: darken($brand-success, 7%);
        }
      }
    }

    .question-number {
      text-align: center;
      font-style: italic;
    }

    .question-wrapper {
      font-size: 18px;
      margin-bottom: 4rem;
      text-align: center;
    }
  }

  @media all and (max-width: 991px) {
    .quiz-ad-bottom,
    .quiz-ad-top {
      .ad-small {
        display: block;
      }

      .ad-large {
        display: none;
      }
    }
  }

  @media all and (max-width: $screen-sm) {
    .content {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}