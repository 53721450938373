.home-ctas {
  background: $brand-primary;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 14px;
  box-shadow: 9px 9px 22px rgba(0,0,0,0.14);

  &::before {
    position: absolute;
    display: block;
    opacity: 0.1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/images/education.png');
    background-size: 250px;
  }

  @media all and (max-width: 1199px) {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0;
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

.front .content-wrapper {
  padding-top: 0;
}

.front {
  .header {
    border-bottom: 0;
  }
}

.home {
   h1 {
    text-align: center;
    color: $white;
  }

  h3 {
    text-align: center;
    font-size: 18px;
    color: $white;
    font-weight: 100;
  }

  .teaser-copy {
    padding: 3rem 0;
  }

  .tagline {
    text-align: center;
    font-weight: 400;
    max-width: 500px;
    margin: 0 auto;
  }

  .above-footer {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.quiz-cta-container {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-around;
  padding: 4rem 1rem;

  .quiz-cta {
    flex: 0 0 30%;
    text-align: center;
    padding: 3rem 1rem;
    height: 280px;
    background-color: $white;
    border-radius: 3px;
    position: relative;
    box-shadow: 1px 1px 13px rgba(0,0,0,0.05);

    &.ad-container {
      padding: 0;
    }
  }

  .quiz-cta__title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;

    .subtitle {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .quiz-cta__body {
    z-index: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    a {
      display: block;
      color: $white;
      background: $brand-primary;
      padding: 1rem 3rem;
      border-radius: 3px;
      font-size: 24px;
      text-transform: uppercase;
      transition: all 0.2s ease;
      font-weight: 700;
      letter-spacing: 2px;

      &:hover,
      &:focus {
        background: darken($brand-primary, 7%);
        text-decoration: none;
      }
    }
  }

  .quiz-cta__icon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-size: 220px;

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      color: #f6f6f6;
    }
  }

  @media all and (max-width: 991px) {
    .quiz-cta {
      flex: 0 0 45%;

      &.ad-container {
        display: none;
      }
    }
  }

  @media all and (max-width: 615px) {
    flex-wrap: wrap;

    .quiz-cta {
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
  }
}